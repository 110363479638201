import React, { useState, useLayoutEffect, useEffect } from "react";
import { EachData } from "components/ResultViewer/DataList/EachData";
import { ButtonList } from "components/ResultViewer/DataList/ButtonList";

export const EachLine = (props) => {
  const {
    fd,
    frame,
    setFrame,
    loadData,
    setLoadData,
    listType,
    setPDFView,
    setVideoStatus,
    useVideo,
    openRightMenu,
    useMultiSelect,
  } = props;

  const [isPopUp, setIsPopUp] = useState(false);
  useLayoutEffect(() => setIsPopUp(false), [frame, listType]);

  const onClickEachData = () => {
    if (isPopUp) return;
    if (frame !== fd.Frame) {
      setVideoStatus(true);
      setFrame(fd.Frame);
      setPDFView(false);
      useVideo.current &&
        useVideo.current.paused &&
        (useVideo.current.currentTime = fd.Frame / 30);
    }
  };

  const setAndMove = (data) => {
    setLoadData(undefined);
    setLoadData(data);
    //setFrame(-1);
    setPDFView(false);
  };

  const style = {
    override: {
      position: "absolute",
      height: "98vh",
      width: "98vw",
      display: isPopUp ? "block" : "none",
      left: 0,
      top: 0,
    },
    relative: { position: "relative" },
  };

  return (
    <>
      <div style={style.override} onClick={() => setIsPopUp(false)} />
      <div style={style.relative}>
        <ButtonList
          listType={listType}
          check={fd.Check}
          selecting={isPopUp}
          onClick={(id) => {
            let data = JSON.parse(JSON.stringify(loadData));
            data[frame].Check = id;
            setAndMove(data);
          }}
        />
        <EachData
          key={fd.Frame}
          loadEachData={fd}
          frame={frame}
          onClick={onClickEachData}
          openRightMenu={() => {
            openRightMenu();
            setIsPopUp(false);
          }}
          togglePopUp={() => setIsPopUp(!isPopUp)}
          useMultiSelect={useMultiSelect}
        />
      </div>
    </>
  );
};
