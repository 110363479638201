import { useState, useLayoutEffect } from "react";
import { DataListCanceler } from "components/ResultViewer/DataList/DataListCanceler";
import { ButtonList } from "components/ResultViewer/DataList/ButtonList";

export const HeadDataLine = (props) => {
  const {
    setFrame,
    loadData,
    setLoadData,
    useMultiSelect,
    filteredData,
    borderData,
    listType,
    setPDFView,
    setVideoStatus,
    frame,
  } = props;
  const { multiSelect } = useMultiSelect;

  useLayoutEffect(() => setIsPopUp(false), [frame, listType]);

  const [isPopUp, setIsPopUp] = useState(false);

  const onClickHeadLine = () => {
    setFrame(-1);
    setPDFView(false);
    setVideoStatus(true);
  };
  const setAndMove = (data) => {
    setLoadData(undefined);
    setLoadData(data);
    setFrame(-1);
    setPDFView(false);
  };

  const style = {
    override: {
      position: "absolute",
      height: "98vh",
      width: "98vw",
      display: isPopUp ? "block" : "none",
      left: 0,
      top: 0,
    },
    relative: { position: "relative" },
  };
  return (
    <>
      <div style={style.override} onClick={() => setIsPopUp(false)} />
      <div style={style.relative}>
        <ButtonList
          listType={listType}
          check={-1}
          selecting={isPopUp}
          onClick={(id) => {
            let data = JSON.parse(JSON.stringify(loadData));
            multiSelect.forEach((ms) => (data[ms].Check = id));
            setAndMove(data);
          }}
        />
        <DataListCanceler
          key={-1}
          filteredData={filteredData}
          borderData={borderData}
          onClick={onClickHeadLine}
          togglePopUp={() => setIsPopUp(!isPopUp)}
          useMultiSelect={useMultiSelect}
        />
      </div>
    </>
  );
};
