import React, { useState, useEffect, useLayoutEffect, useMemo } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { Storage } from "@aws-amplify/storage";
import { PDFViewer } from "@react-pdf/renderer";
import IPA from "fonts/ipaexg.ttf";
import logo from "assets/logo_nomal.png";
import 鉄塔 from "assets/tower.jpg";
import { MITAI_TEXTBUTTON } from "components/mitoyoAI-style";
import { filterCheckStatus } from "util/resultcsv_control/filterCheckStatus";

import Lottie from "react-lottie";
import animationData from "assets/9818-infinity-loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

//component
const MenuItem = ({ title, data }) => {
  const style = {
    borderWidth: "1",
    borderTopWidth: "0",
    borderRightWidth: "0",
    borderColor: "black",
    textAlign: "center",
  };
  return (
    <View style={{ display: "flex", flexDirection: "row", flex: 1 }}>
      <View style={{ ...style, flex: 1, borderLeftWidth: "0" }}>
        <Text>{title}</Text>
      </View>
      <View style={{ ...style, flex: 3 }}>
        <Text>{data}</Text>
      </View>
    </View>
  );
};

/**
 *
 * @param {タイトル(data.title)} data
 * @param {} props
 * @param {進捗血（％で出力）} pp
 * @returns 送電線の現在地の図
 * props.props.bread[1].dataset.lineWidth
 * props.frame / props.csvData
 *
 */
const PositionData = ({
  data,
  pp,
  lineWidth,
  frame,
  csvData,
  startTower = "No.1",
  endTower = "No.2",
}) => {
  const pd = (num) => ((384 - 26) * num) / 100 + 26;
  const pdw = (num) => ((384 - 26) * num) / 100;
  const style = {
    borderWidth: "1",
    borderTopWidth: "0",
    borderLeftWidth: "0",
    borderRightWidth: "0",
    borderColor: "black",
  };
  return (
    <View style={{ display: "flex", flexDirection: "column", flex: 10 }}>
      <View style={{ ...style, flex: 1 }}>
        <Text>{data.title}</Text>
      </View>
      <View style={{ ...style, flex: 9, position: "relative" }}>
        <Image src={鉄塔} width={300} />
        <View style={{ position: "absolute", left: pd(40), top: 160 }}>
          <Text>
            {pp}% {Math.round(lineWidth * (pp / 100))}m
          </Text>
        </View>
        <Text style={{ position: "absolute", left: "0%", top: 160 }}>
          {startTower}
        </Text>
        <Text
          style={{
            position: "absolute",
            right: "0%",
            top: 160,
            fontFamily: "Koruri",
          }}
        >
          {endTower}
        </Text>

        <View
          style={{
            width: 1,
            height: 100,
            backgroundColor: "blue",
            borderRadius: 10,
            position: "absolute",
            left: 27,
            top: 62,
          }}
        />
        <View
          style={{
            width: 1,
            height: 100,
            backgroundColor: "blue",
            borderRadius: 10,
            position: "absolute",
            left: pd((frame / csvData.length) * 100),
            top: 62,
          }}
        />
        <View
          style={{
            width: pdw((frame / csvData.length) * 100),
            height: 1,
            backgroundColor: "blue",
            borderRadius: 10,
            position: "absolute",
            left: pd(0),
            top: 120,
          }}
        />
        <View
          style={{
            width: 10,
            height: 10,
            backgroundColor: "blue",
            borderRadius: 10,
            position: "absolute",
            left: pd((frame / csvData.length) * 100) - 4,
            top: 57,
          }}
        />
      </View>
    </View>
  );
};

const TriplePictureData = ({ title, data }) => {
  const style = {
    borderWidth: "1",
    borderTopWidth: "0",
    borderRightWidth: "0",
    borderColor: "black",
  };
  return (
    <View style={{ display: "flex", flexDirection: "column", flex: 15 }}>
      <View style={{ ...style, flex: 1 }}>
        <Text>{title}</Text>
      </View>
      <View style={{ ...style, flex: 15 }}>
        <View style={{ alignContent: "center", alignItems: "center" }}>
          <Image src={data} />
        </View>
      </View>
    </View>
  );
};
const MemoData = ({ title, data }) => {
  const style = {
    borderWidth: "1",
    borderTopWidth: "0",
    borderRightWidth: "0",
    borderColor: "black",
  };
  return (
    <View style={{ display: "flex", flexDirection: "column", flex: 7 }}>
      <View style={{ ...style, flex: 1 }}>
        <Text>{title}</Text>
      </View>
      <View style={{ ...style, flex: 6, borderLeftWidth: "1" }}>
        <Text>{data?.replace(/\\n/g, "\n")}</Text>
      </View>
    </View>
  );
};

const Layout = ({ frame, csvData, dataset0, dataset1, picture }) => {
  const pp = Math.round((frame / csvData.length) * 1000) / 10;
  const commonStyle = {
    fontFamily: "Koruri",
    borderColor: "black",
    borderWidth: "2",
    flex: 1,
  };
  const style = {
    main: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      height: "97%",
      margin: "1%",
      borderStyle: "solid",
    },
    left: { ...commonStyle, borderRightWidth: "0", overflow: "hidden" },
    right: { ...commonStyle, borderLeftWidth: "0" },
  };
  return (
    <View style={style.main}>
      {/*左の項目 */}
      <View style={style.left}>
        <MenuItem title={"実施日"} data={dataset0.checkDate} />
        <MenuItem title={"線路名"} data={dataset0.lineName} />
        <MenuItem title={"区間"} data={dataset1.positionName} />
        <MenuItem title={"径間長"} data={dataset1.lineWidth + "m"} />
        <MenuItem title={"線種"} data={dataset1.lineData} />
        <PositionData
          data={{ title: "異常箇所" }}
          pp={pp}
          lineWidth={dataset1.lineWidth}
          frame={frame}
          csvData={csvData}
          startTower={dataset1.startTower}
          endTower={dataset1.endTower}
        />
        <MemoData title={"異常状態"} data={csvData[frame].Description} />
      </View>
      {/*右の項目 */}
      <View style={style.right}>
        <TriplePictureData title={"異常箇所写真"} data={picture} />
        <MemoData title={"備考欄"} data={csvData[frame].Etc} />
      </View>
    </View>
  );
};

export default function OutputPDF({ frame, props, csvData }) {
  const { bread } = props;
  Font.register({ family: "Koruri", src: IPA });
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      display: "flex",
      fontFamily: "Koruri",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  });
  const [picture, setPicture] = useState(undefined);
  useLayoutEffect(() => {
    Storage.get(
      `${bread[0].dataset.dataDir}/${
        bread[1].dataset.dataDir
      }/output_resync_marge_cropped/${("00000" + frame).slice(-5)}.jpg`,
      {
        level: "private",
        download: true,
      }
    ).then((d) => setPicture(URL.createObjectURL(d.Body)));
  }, []);

  const MyDoc = React.memo(
    () => (
      <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
          <Layout
            frame={frame}
            csvData={csvData}
            dataset0={bread[0].dataset}
            picture={picture}
            dataset1={bread[1].dataset}
          />
        </Page>
      </Document>
    ),
    [frame, csvData, bread, picture]
  );
  return (
    <div style={{ flex: 2, width: "100%", height: "calc(100vh - 188px)" }}>
      {picture ? (
        <>
          <div style={{ position: "absolute", right: 30, bottom: 30 }}>
            <MITAI_TEXTBUTTON>
              <PDFDownloadLink document={<MyDoc />} fileName="AI報告書.pdf">
                ファイルのダウンロード
              </PDFDownloadLink>
            </MITAI_TEXTBUTTON>
          </div>
          <PDFViewer style={{ flex: 3, width: "100%", height: "100%" }}>
            <MyDoc />
          </PDFViewer>
        </>
      ) : (
        <PDFViewer style={{ flex: 3, width: "100%", height: "100%" }}>
          <Document>
            <Page style={styles.page}>
              <Text>loading....</Text>
            </Page>
          </Document>
        </PDFViewer>
      )}
    </div>
  );
}

export function OutputAllPDF({ csvData, props }) {
  const { bread } = props;
  const [picture, setPicture] = useState(undefined);
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      display: "flex",
      fontFamily: "Koruri",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  });
  const exportList = useMemo(
    () =>
      csvData.filter((d, index) =>
        filterCheckStatus(d, index, 0, csvData, "Final")
      ),
    [csvData]
  );
  useLayoutEffect(() => {
    Promise.all(
      exportList.map((d) =>
        Storage.get(
          `${bread[0].dataset.dataDir}/${
            bread[1].dataset.dataDir
          }/output_resync_marge_cropped/${("00000" + d.Frame).slice(-5)}.jpg`,
          {
            level: "private",
            download: true,
          }
        )
      )
    ).then((d) => setPicture(d.map((data) => URL.createObjectURL(data.Body))));
  }, []);
  const MyDoc = () => (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        {exportList.map((d, index) => (
          <Layout
            frame={d.Frame}
            csvData={csvData}
            dataset0={bread[0].dataset}
            picture={picture[index]}
            dataset1={bread[1].dataset}
            key={d.Frame}
          />
        ))}
      </Page>
    </Document>
  );
  return (
    <div style={{ flex: 2, width: "100%", height: "calc(100vh - 188px)" }}>
      {picture ? (
        <>
          <div style={{ position: "absolute", right: 30, bottom: 30 }}>
            <MITAI_TEXTBUTTON>
              <PDFDownloadLink document={<MyDoc />} fileName="AI報告書.pdf">
                {({ url }) =>
                  url ? (
                    "ファイルのダウンロード"
                  ) : (
                    <Lottie options={defaultOptions} height={150} width={150} />
                  )
                }
              </PDFDownloadLink>
            </MITAI_TEXTBUTTON>
          </div>
          <PDFViewer style={{ flex: 3, width: "100%", height: "100%" }}>
            <MyDoc />
          </PDFViewer>
        </>
      ) : (
        <PDFViewer style={{ flex: 3, width: "100%", height: "100%" }}>
          <Document>
            <Page style={styles.page}>
              <Text>loading....</Text>
            </Page>
          </Document>
        </PDFViewer>
      )}
    </div>
  );
}
