export const BreadData = ({ navigation, pp, handleClickWithCtrlAlt }) => {
  const handleClick = (e /* : React.MouseEvent<HTMLButtonElement> */) => {
    if (pp) {
      navigation();
      return;
    }
    if (handleClickWithCtrlAlt) {
      handleClickWithCtrlAlt(e);
      return;
    } else {
      navigation();
    }
  };
  const handleClick2 = (e /* : React.MouseEvent<HTMLButtonElement> */) => {
    if (pp.bread[1]) {
      navigation("selectData2", {
        dataset: pp.bread[0].dataset,
        bread: [pp.bread[0]],
      });
      return;
    }
    if (handleClickWithCtrlAlt) {
      handleClickWithCtrlAlt(e);
      return;
    } else {
      navigation("selectData2", {
        dataset: pp.bread[0].dataset,
        bread: [pp.bread[0]],
      });
    }
  };
  return (
    <>
      <div
        style={{ ...baseSheet, color: pp ? "darkgray" : "black" }}
        onClick={(e) => handleClick(e)}
      >
        データの選択
      </div>
      {pp && pp.bread[0] ? (
        <div
          style={{ ...baseSheet, color: pp.bread[1] ? "darkgray" : "black" }}
          onClick={(e) => handleClick2(e)}
        >
          {` ＞ ${pp.bread[0].dataset.lineName}`}
        </div>
      ) : null}
      {pp && pp.bread[1] ? (
        <div style={baseSheet}>{` ＞ ${pp.bread[1].dataset.positionName}`}</div>
      ) : null}
    </>
  );
};
const baseSheet = {
  fontSize: "1.25em",
  marginTop: 0,
  marginBottom: 3,
  cursor: "pointer",
  userSelect: "none",
};
