import { MITAI_TEXTBUTTON } from "components/mitoyoAI-style";
export const ButtonList = (props) => {
  const { listType, check, selecting, onClick, style } = props;
  const useButtons = (id) => ({
    true: check === id,
    style: { fontSize: 10, margin: 5 },
    onClick: () => onClick(id),
  });
  const AIButtons = () => (
    <>
      <MITAI_TEXTBUTTON {...useButtons(100)}>報告</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(200)}>報告しない</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(300)}>異常ではない</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(400)}>付属部品</MITAI_TEXTBUTTON>
    </>
  );
  const UserButtons = () => (
    <>
      <MITAI_TEXTBUTTON {...useButtons(100)}>報告</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(200)}>報告しない</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(300)}>異常ではない</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(400)}>付属部品</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(0)}>削除</MITAI_TEXTBUTTON>
    </>
  );
  const PartsButtons = () => (
    <>
      <MITAI_TEXTBUTTON {...useButtons(100)}>報告</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(200)}>報告しない</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(300)}>異常ではない</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(400)}>付属部品</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(0)}>削除</MITAI_TEXTBUTTON>
    </>
  );
  const NonReportButtons = () => (
    <>
      <MITAI_TEXTBUTTON {...useButtons(100)}>報告</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(200)}>報告しない</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(300)}>異常ではない</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(400)}>付属部品</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(0)}>削除</MITAI_TEXTBUTTON>
    </>
  );
  const OutputButton = () => (
    <>
      <MITAI_TEXTBUTTON {...useButtons(100)}>報告</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(200)}>報告しない</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(300)}>異常ではない</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(400)}>付属部品</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(0)}>削除</MITAI_TEXTBUTTON>
    </>
  );
  const NewButton = () => (
    <>
      <MITAI_TEXTBUTTON {...useButtons(101)}>報告(手動登録)</MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(201)}>
        報告しない(手動登録)
      </MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(301)}>
        異常ではない(手動登録)
      </MITAI_TEXTBUTTON>
      <MITAI_TEXTBUTTON {...useButtons(401)}>
        付属部品(手動登録)
      </MITAI_TEXTBUTTON>
    </>
  );

  const styles = {
    position: "absolute",
    height: 0,
    right: 0,
    top: "100%",
    display: selecting ? "block" : "none",
    zIndex: 100,
    marginRight: 10,
    backgroundColor: "#559C27",
    width: 200,
  };

  return (
    <div style={{ ...styles, ...style }}>
      {(() => {
        switch (listType) {
          case "AI":
            return <AIButtons />;
          case "User":
            return <UserButtons />;
          case "Parts":
            return <PartsButtons />;
          case "NonReport":
            return <NonReportButtons />;
          case "Final":
            return <OutputButton />;
          case "New":
            return <NewButton />;
          default:
            return <></>;
        }
      })()}
    </div>
  );
};
