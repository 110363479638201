import { useEffect, useState } from "react";
import { MITAI_BORDERLINE } from "components/mitoyoAI-style";
import { DataListTab } from "components/ResultViewer/DataList/DataListTab";
import { filterCheckStatus } from "util/resultcsv_control/filterCheckStatus";
import { EachDataListBox } from "components/ResultViewer/DataList/EachDataListBox";
import { MemoInputArea } from "components/ResultViewer/DataList/MemoInputArea";
export const DataList = ({
  borderData,
  frame,
  setFrame,
  setPDFView,
  setVideoStatus,
  loadData,
  setLoadData,
  useVideo,
  openRightMenu,
}) => {
  //データの閾値定義
  const [listType, setListType] = useState("AI"); //データリストのタブ制御用
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (!loadData) return;
    const returnData = loadData.filter((d, index) =>
      filterCheckStatus(d, index, borderData, loadData, listType)
    );
    setFilteredData(returnData);
  }, [borderData, listType, loadData]);

  return (
    <div style={styles.left1}>
      <MITAI_BORDERLINE />
      {/* リスト */}
      <DataListTab
        styles={styles.left2}
        listType={listType}
        setListType={setListType}
      />
      <EachDataListBox
        loadData={loadData}
        frame={frame}
        setLoadData={setLoadData}
        setVideoStatus={setVideoStatus}
        setPDFView={setPDFView}
        setFrame={setFrame}
        openRightMenu={openRightMenu}
        filteredData={filteredData}
        listType={listType}
        useVideo={useVideo}
        borderData={borderData}
      />
      <MITAI_BORDERLINE />
      <MemoInputArea
        frame={frame}
        loadData={loadData}
        setLoadData={setLoadData}
      />
    </div>
  );
};
const styles = {
  left1: {
    display: "flex",
    flexDirection: "column",
    borderWidth: 2,
    borderRightWidth: 0,
    borderColor: "#A9D18E",
    borderStyle: "solid",
    height: "calc(100vh - 180px)",
    flex: 6,
  },
  left2: { display: "flex", flexDirection: "row", height: 30, marginTop: 5 },
};
