import { MITAI_TABBUTTON } from "components/mitoyoAI-style";

export const PDFTabMenu = ({ PDFView, setPDFView, frame }) => {
  const useButtons = (color, bool, onClick) => ({
    Bcolor: color,
    current: bool,
    onClick: onClick,
  });
  return (
    <>
      <MITAI_TABBUTTON
        {...useButtons("#A9D18E", !PDFView, () => setPDFView(false))}
      >
        チェック
      </MITAI_TABBUTTON>
      <MITAI_TABBUTTON
        {...useButtons("#EF2929", PDFView === "single", () =>
          setPDFView("single")
        )}
        style={{ display: frame !== -1 ? "unset" : "none" }}
      >
        この傷の報告書を作成
      </MITAI_TABBUTTON>

      <MITAI_TABBUTTON
        {...useButtons("#EF2929", PDFView === "All", () => setPDFView("All"))}
      >
        全体のPDFを出力
      </MITAI_TABBUTTON>
    </>
  );
};
