import Checkbox from "@mui/material/Checkbox";
import { useLayoutEffect, useState } from "react";
import { MITAI_TEXTBUTTON } from "components/mitoyoAI-style";
export const DataListCanceler = (props) => {
  const { onClick, useMultiSelect, filteredData, borderData, togglePopUp } =
    props;
  const { multiSelect, setMultiSelect } = useMultiSelect;

  const [checkBox, setCheckbox] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  useLayoutEffect(() => {
    setCheckbox(
      multiSelect.length > 0 && multiSelect.length >= filteredData.length
    );
    setIndeterminate(
      multiSelect.length > 0 && multiSelect.length < filteredData.length
    );
  }, [multiSelect, borderData, filteredData.length]);

  const onClickCheckbox = () => {
    if (multiSelect.length === filteredData.length) {
      setMultiSelect([]);
    } else {
      const frameList = filteredData.map((d) => d.Frame);
      setMultiSelect(frameList);
    }
  };

  const style = {
    select: {
      display: "flex",
      flexDirection: "row",
      fontSize: 10,
      margin: 5,
      position: "relative",
    },
    text: {
      display: "flex",
      flexDirection: "row",
      padding: 10,
      flex: 2,
    },
  };

  return (
    <div style={styles.left4} onClick={isFocus ? () => {} : onClick}>
      <Checkbox
        onClick={onClickCheckbox}
        checked={checkBox}
        indeterminate={indeterminate}
      />
      <div style={styles.left5}>選択を解除</div>
      <div style={styles.left7}>
        {multiSelect.length > 0 && (
          <MITAI_TEXTBUTTON
            true
            style={style.select}
            onClick={togglePopUp}
            onMouseEnter={() => setIsFocus(true)}
            onMouseLeave={() => setIsFocus(false)}
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              <div>一括で状態を登録</div>
              <Triangle />
            </span>
          </MITAI_TEXTBUTTON>
        )}
      </div>
    </div>
  );
};

const Triangle = () => (
  <div
    style={{
      width: 10,
      height: 10,
      borderTop: "5px solid white",
      borderBottom: "5px solid transparent",
      borderRight: "5px solid transparent",
      borderLeft: "5px solid transparent",
      marginTop: 5,
      marginLeft: 5,
    }}
  />
);

const styles = {
  left4: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "unset",
    cursor: "default",
  },
  left5: { display: "flex", flexDirection: "row", padding: 10, flex: 1 },
  left6: { display: "flex", flexDirection: "row", cursor: "default" },
  left7: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignContent: "center",
    alignItems: "center",
    placeContent: "flex-end",
  },
};
