import { MITAI_TEXTBUTTON } from "components/mitoyoAI-style";
import Checkbox from "@mui/material/Checkbox";
import { useLayoutEffect, useState } from "react";
export const EachData = (props) => {
  const {
    loadEachData,
    frame,
    onClick,
    openRightMenu,
    togglePopUp,
    useMultiSelect,
  } = props;
  const { multiSelect, setMultiSelect } = useMultiSelect;

  const [checkBox, setCheckbox] = useState(false);

  useLayoutEffect(() => {
    setCheckbox(!!multiSelect.find((d) => d === loadEachData.Frame));
  }, [multiSelect]);

  const onClickCheckbox = () => {
    if (multiSelect.find((d) => d === loadEachData.Frame)) {
      setMultiSelect(multiSelect.filter((d) => d !== loadEachData.Frame));
    } else {
      setMultiSelect([...multiSelect, loadEachData.Frame]);
    }
  };
  return (
    <div
      style={{
        ...styles.left6,
        backgroundColor: frame === loadEachData.Frame ? "#A9D18E50" : "unset",
      }}
      onClick={onClick}
    >
      <Checkbox onClick={onClickCheckbox} checked={checkBox} />
      <div style={styles.left5}>{loadEachData.Frame}</div>
      <Text check={loadEachData.Check} />
      <div style={styles.left7}>
        {frame === loadEachData.Frame && (
          <>
            <MITAI_TEXTBUTTON true style={style.select} onClick={togglePopUp}>
              <span style={{ display: "flex", alignItems: "center" }}>
                <div>状態を選択</div>
                <Triangle />
              </span>
            </MITAI_TEXTBUTTON>
            <MITAI_TEXTBUTTON
              style={{ fontSize: 10, margin: 5 }}
              onClick={openRightMenu}
              true
            >
              詳細
            </MITAI_TEXTBUTTON>
          </>
        )}
      </div>
    </div>
  );
};
const Text = ({ check }) => {
  switch (check) {
    case 1:
    case 100:
      return <div style={style.text}>報告</div>;
    case 101:
      return <div style={style.text}>報告(追加)</div>;
    case 2:
    case 200:
      return <div style={style.text}>報告しない</div>;
    case 201:
      return <div style={style.text}>報告しない(追加)</div>;
    case 3:
    case 300:
      return <div style={style.text}>異常ではない</div>;
    case 301:
      return <div style={style.text}>異常ではない(追加)</div>;
    case 4:
    case 400:
      return <div style={style.text}>付属部品</div>;
    case 401:
      return <div style={style.text}>付属部品(追加)</div>;
    default:
      return <></>;
  }
};
const Triangle = () => (
  <div
    style={{
      width: 10,
      height: 10,
      borderTop: "5px solid white",
      borderBottom: "5px solid transparent",
      borderRight: "5px solid transparent",
      borderLeft: "5px solid transparent",
      marginTop: 5,
      marginLeft: 5,
    }}
  />
);

const styles = {
  left5: { display: "flex", flexDirection: "row", padding: 10, flex: 1 },
  left6: { display: "flex", flexDirection: "row", cursor: "default" },
  left7: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignContent: "center",
    alignItems: "center",
    placeContent: "flex-end",
  },
};

const style = {
  select: {
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
    margin: 5,
    position: "relative",
  },
  text: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    flex: 2,
  },
};
