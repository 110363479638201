import mitaiLogo from "assets/logo_white.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { HeaderUserMenu } from "components/Header/HeaderUserMenu";
import { CSSProperties, FC, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";

type Props = {
  setTopPage: () => void;
  isTopMenu: boolean;
};
export const Header: FC<Props> = (Props) => {
  const { setTopPage, isTopMenu } = Props;
  const { authStatus } = useAuthenticator((context) => [context.user]);
  const [userMenu, setUserMenu] = useState<boolean>(false);
  const style: CSSProperties = {
    display: "flex",
    height: 50,
    width: "100%",
    flexDirection: "row",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: "#A9D18E",
    zIndex: 10,
    overflow: "clip",
  };

  return (
    <div style={style}>
      <img
        src={mitaiLogo}
        height={"100%"}
        style={{ padding: 10, marginLeft: 20 }}
        alt=""
        onClick={setTopPage}
      />
      <div
        style={{ height: 60, width: 2, margin: 10, backgroundColor: "white" }}
      />
      {isTopMenu || authStatus === "unauthenticated" || (
        <FAHeader icon={faHome} onClick={setTopPage} />
      )}
      <h2 style={{ color: "white" }} onClick={setTopPage}>
        AI送電線点検結果表示システム
      </h2>
      <div style={{ flex: 1 }} />
      {authStatus === "authenticated" && (
        <FAHeader icon={faUser} onClick={() => setUserMenu(true)} />
      )}

      {userMenu && <HeaderUserMenu setUserMenu={setUserMenu} />}
    </div>
  );
};

type FAHeaderProps = {
  icon: IconProp;
  onClick: () => void;
};
const FAHeader: FC<FAHeaderProps> = ({ icon, onClick }) => (
  <FontAwesomeIcon
    icon={icon}
    style={{ width: 30, height: 30, color: "white", margin: 10 }}
    onClick={onClick}
  />
);
