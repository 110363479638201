import React, { useState, useEffect, useRef } from "react";
import { InputArea } from "components/ResultViewer/DataList/InputArea";
export const MemoInputArea = (props) => {
  const { frame, loadData, setLoadData } = props;

  const useInputModal = (type, title) => {
    const inputRef = useRef();
    const [data, setData] = useState("");

    useEffect(() => {
      switch (true) {
        case !loadData:
          return;
        case frame === -1:
        case !loadData[frame]:
        case !Object.keys(loadData[frame]).includes(type):
          setData("");
          return;
        default:
          setData(loadData[frame][type].replace(/\\n/g, "\n"));
          return;
      }
    }, [type, frame, loadData]);

    const onMouseLeave = (d) => {
      if (frame == -1) return;
      let data = JSON.parse(JSON.stringify(loadData));
      data[frame][type] = d.target.value.replace(/\n/g, "\\n");
      setLoadData(data);
    };

    return { inputRef, data, setData, onMouseLeave, frame, title };
  };

  return (
    <div style={styles}>
      <InputArea {...useInputModal("Description", "状態記入")} />
      <InputArea {...useInputModal("Etc", "備考情報")} />
    </div>
  );
};

const styles = {
  display: "flex",
  flexDirection: "row",
  borderWidth: 2,
  borderTopWidth: 0,
  padding: 5,
  borderStyle: "solid",
  borderColor: "#A9D18E",
};
