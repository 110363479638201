export const getWindowSize = () => {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    w = w.innerWidth || e.clientWidth || g.clientWidth,
    h = w.innerHeight || e.clientHeight || g.clientHeight;

  return {
    width: w,
    height: h,
  };
};
