export function jsonToCsvText(json, delimiter = ",") {
  var header = Object.keys(json[0]).join(delimiter) + "\n";
  var body = json
    .map((d) =>
      Object.keys(d)
        .map((key) => d[key])
        .join(delimiter)
    )
    .join("\n");
  return header + body;
}
