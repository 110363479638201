import React, { useEffect, useState } from "react";
import {
  MITAI_BORDERLINE,
  MITAI_TEXTBUTTON,
  MITAI_TABBUTTON,
} from "components/mitoyoAI-style";
import { BreadData } from "components/ResultViewer/BreadData";
import RightWindow from "components/RightWindow";
import { Storage } from "@aws-amplify/storage";
import Lottie from "react-lottie";
import animationData from "assets/9818-infinity-loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export function SelectData({ navigation }) {
  const [rightMenu, toggleRightMenu] = useState(false);
  const [dataList, setDataList] = useState(undefined);

  //読み込みシーケンス
  useEffect(() => {
    Storage.get(`dataList.json`, {
      level: "private",
      contentType: "text/plain",
      download: true,
      cacheControl: "no-store", //テスト時にコメントアウト
    })
      .then((res) => res.Body.text())
      .then(JSON.parse)
      .then(setDataList)
      .catch((e) => {
        Storage.put("test.txt", "Private Content", {
          level: "private",
          contentType: "text/plain",
        });
      });
  }, []);
  const goToNextView = (data) => {
    const breadData = [{ dataset: data, page: "SelectData2" }];
    navigation("selectData2", { dataset: data, bread: breadData });
  };
  const openRightPannel = ({ data, index }) => {
    toggleRightMenu(
      <RightPanel
        data={data}
        dataList={dataList}
        setDataList={setDataList}
        index={index}
      />
    );
  };

  const handleClickWithCtrlAlt = (e) => {
    if (e.ctrlKey && e.altKey) {
      const data = prompt("※システム管理者用※\nデータを入力してください");
      switch (data) {
        case "eddir":
          const returnData = JSON.stringify(dataList);
          const hoge = prompt(
            "※システム管理者用※\nデータを入力してください",
            returnData
          );
          if (hoge === null) return;
          Storage.put(`dataList.json`, JSON.parse(hoge), {
            level: "private",
            contentType: "text/plain",
          }).catch(() => {});
          setDataList(hoge);

          break;
        default:
          break;
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <BreadData
          navigation={navigation}
          handleClickWithCtrlAlt={handleClickWithCtrlAlt}
        />
        <div style={{ flex: 1 }} />
      </div>
      <MITAI_BORDERLINE />

      <div style={{ display: "flex", flexDirection: "row" }}>
        <h4 style={{ flex: 2 }}>路線名</h4>
        <h4 style={{ flex: 2 }}>日時</h4>
        <div style={{ flex: 1 }} />
      </div>
      <div style={{ width: "100%", height: 1, backgroundColor: "#8A8A8A" }} />

      <div style={{ height: "calc(100vh - 230px)", overflowY: "auto" }}>
        {/* ここから下動的変化 */}
        {dataList ? (
          dataList.map((data, index) => (
            <DataList
              data={data}
              goToNextView={goToNextView}
              openRightPannel={openRightPannel}
              index={index}
            />
          ))
        ) : (
          <Lottie options={defaultOptions} height={150} width={150} />
        )}
      </div>
      {rightMenu && (
        <RightWindow
          closeButton={() => toggleRightMenu(false)}
          title={"路線情報"}
        >
          {rightMenu}
        </RightWindow>
      )}
    </div>
  );
}
const DataList = ({ data, goToNextView, openRightPannel, index }) => {
  const [isHover, setIsHover] = useState(false);
  const [isDetailButton, setIsDetailButton] = useState(false);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: isHover ? "#EDEDEC60" : undefined,
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={() =>
          isDetailButton ? openRightPannel({ data, index }) : goToNextView(data)
        }
      >
        <p style={{ flex: 2 }}>{data.lineName}</p>
        {/* <p style={{ flex: 2 }}>{data.datalist}</p> */}
        <p style={{ flex: 2 }}>{data.checkDate}</p>
        <div
          style={{
            flex: 1,
            alignContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          {isHover && (
            <MITAI_TEXTBUTTON
              style={{ zIndex: 10 }}
              onMouseEnter={() => setIsDetailButton(true)}
              onMouseLeave={() => setIsDetailButton(false)}
            >
              詳細を見る
            </MITAI_TEXTBUTTON>
          )}
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: 1,
          backgroundColor: "#8A8A8A",
        }}
      />
    </>
  );
};

const RightPanel = (props) => {
  const { data, dataList, setDataList, index } = props;
  const [editMode, setMode] = useState("View");
  const [lineName, setLineName] = useState(data.lineName);
  const [lineData, setLineData] = useState(data.lineData);
  const [lineDate, setLineDate] = useState(data.checkDate);
  const [etc, setEtc] = useState(data.etc);
  const update = () => {
    const returnData = JSON.parse(JSON.stringify(dataList));
    returnData[index].lineName = lineName;
    returnData[index].lineData = lineData;
    returnData[index].etc = etc;
    returnData[index].checkDate = lineDate;
    Storage.put(`dataList.json`, returnData, {
      level: "private",
      contentType: "text/plain",
    }).catch(() => {});
    setDataList(returnData);
    setMode("View");
  };
  const unUpdate = () => {
    setLineName(data.lineName);
    setLineData(data.lineData);
    setLineDate(data.checkDate);
    setEtc(data.etc);
    setMode("View");
  };
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        {editMode === "View" && (
          <MITAI_TEXTBUTTON onClick={() => setMode("Edit")}>
            編集
          </MITAI_TEXTBUTTON>
        )}
        {editMode === "Edit" && (
          <>
            <MITAI_TEXTBUTTON onClick={unUpdate}>キャンセル</MITAI_TEXTBUTTON>
            <MITAI_TEXTBUTTON onClick={update}>保存</MITAI_TEXTBUTTON>
          </>
        )}
      </div>
      <RightPanelInfoFormItem
        title="路線名"
        value={lineName}
        setValue={setLineName}
        editMode={editMode}
      />
      <RightPanelInfoFormItem
        title="日時"
        value={lineDate}
        setValue={setLineDate}
        editMode={editMode}
      />
      <RightPanelInfoFormItem
        title="線種情報"
        value={lineData}
        setValue={setLineData}
        editMode={editMode}
      />
      <RightPanelInfoFormItem
        title="備考情報"
        value={etc}
        setValue={setEtc}
        editMode={editMode}
      />
    </div>
  );
};

const RightPanelInfoFormItem = (props) => {
  const { title, value, setValue, editMode } = props;
  switch (editMode) {
    case "View":
      return (
        <p>
          {title}: {value}
        </p>
      );
    case "Edit":
    default:
      return (
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <MITAI_TABBUTTON Bcolor="#A9D18E" current={true} position="Bottom">
              {title}
            </MITAI_TABBUTTON>
          </div>
          <textarea
            style={styles.main5}
            value={value}
            onChange={(d) => setValue(d.target.value)}
          />
        </div>
      );
  }
};

const styles = {
  main1: { display: "flex", flexDirection: "column", flex: 1 },
  main2: { display: "flex", flexDirection: "row" },
  main3: { flex: 1, display: "flex", flexDirection: "row" },
  main5: {
    flex: 1,
    borderStyle: "solid",
    borderColor: "#A9D18E",
    alignItems: "baseline",
    fontSize: 15,
  },
  main6: {
    display: "flex",
    flexDirection: "row",
    borderWidth: 2,
    borderTopWidth: 0,
    padding: 5,
    borderStyle: "solid",
    borderColor: "#A9D18E",
  },
  left1: {
    display: "flex",
    flexDirection: "column",
    borderWidth: 2,
    borderRightWidth: 0,
    borderColor: "#A9D18E",
    borderStyle: "solid",
    height: "calc(100vh - 180px)",
    flex: 6,
  },
  left2: { display: "flex", flexDirection: "row", height: 30, marginTop: 5 },
  left3: {
    display: "flex",
    flex: 3,
    flexDirection: "column",
    overflow: "auto",
    borderWidth: 2,
    borderBottomWidth: 0,
    borderStyle: "solid",
    borderColor: "#A9D18E",
  },
  left4: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "unset",
    cursor: "default",
  },
  left5: { display: "flex", flexDirection: "row", padding: 10, flex: 1 },
  left6: { display: "flex", flexDirection: "row", cursor: "default" },
  left7: {
    display: "flex",
    flexDirection: "row",
    padding: 2,
    alignContent: "center",
    alignItems: "center",
  },
  left8: {
    display: "flex",
    flex: 1,
    width: "100%",
    height: "100%",
    flexDirection: "column",
  },
  left9: { fontSize: 20, color: "darkgray", marginTop: 8 },
  left10: { display: "flex", flexDirection: "row", padding: 10, flex: 1 },
  left11: { flex: 1, display: "block" },
  left12: { flex: 1, height: 200, position: "relative" },
  left13: { position: "absolute", left: 0, top: 90 },
  left14: { position: "absolute", top: 0 },
  left15: {
    width: 1,
    height: 100,
    backgroundColor: "blue",
    borderRadius: 10,
    position: "absolute",
    left: 20,
    top: 35,
  },
  left16: {
    width: 1,
    height: 100,
    backgroundColor: "blue",
    borderRadius: 10,
    position: "absolute",
    top: 35,
  },
  left17: {
    height: 1,
    backgroundColor: "blue",
    borderRadius: 10,
    position: "absolute",
    left: 20,
    top: 50,
  },
  left18: {
    width: 10,
    height: 10,
    backgroundColor: "blue",
    borderRadius: 10,
    position: "absolute",
    top: 130,
  },
  left19: { fontSize: 20, color: "darkgray", marginTop: 8 },
  right1: {
    flex: 5,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "#EF2929",
    paddingRight: 4,
    paddingBottom: 4,
    height: "calc(100vh - 180px)",
  },
  right2: {
    flex: 5,
    alignContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    borderWidth: 2,
    borderColor: "#A9D18E",
    borderLeftWidth: 0,
    borderStyle: "solid",
    height: "calc(100vh - 180px)",
  },
  right3: {
    position: "relative",
    right: "5rem",
    top: 0,
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    width: "5rem",
    marginTop: 10,
  },
  right4: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
};
