import { MITAI_TABBUTTON } from "components/mitoyoAI-style";

export const InputArea = (props) => {
  const { inputRef, data, setData, frame, onMouseLeave, title } = props;
  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <MITAI_TABBUTTON Bcolor={"#A9D18E"} current={true} position={"Bottom"}>
          {title}
        </MITAI_TABBUTTON>
      </div>
      <textarea
        style={styles}
        ref={inputRef}
        value={data}
        onChange={(d) => setData(d.target.value)}
        disabled={frame === -1}
        placeholder={
          frame === -1
            ? "フレームを選択してください。"
            : "情報を入力してください。"
        }
        onMouseLeave={onMouseLeave}
      />
    </div>
  );
};

const styles = {
  flex: 1,
  borderStyle: "solid",
  borderColor: "#A9D18E",
  alignItems: "baseline",
  fontSize: 15,
};
