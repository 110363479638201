import { FC, useEffect, useState } from "react";
import { MITAI_BORDERLINE, MITAI_TOPTEXT } from "components/mitoyoAI-style";
import ResultVideo from "page/ResultViewer/resultDisplay";
import { SelectData } from "page/ResultViewer/SelectData";
import { SelectData2 } from "page/ResultViewer/SelectData2";

export const ResultsViewer: FC = () => {
  const [page, setPage] = useState("selectData");
  const [prop, setProp] = useState(undefined);
  const navigation = (name = "selectData", prop = undefined) => {
    setPage(name);
    setProp(prop);
  };

  return (
    <div style={styleSheet.lapped}>
      <MITAI_TOPTEXT>点検データの閲覧</MITAI_TOPTEXT>
      <MITAI_BORDERLINE />
      <div style={styleSheet.inner}>
        {page === "selectData" && <SelectData navigation={navigation} />}
        {page === "selectData2" && (
          <SelectData2 props={prop} navigation={navigation} />
        )}
        {page === "resultVideo" && (
          <ResultVideo props={prop} navigation={navigation} />
        )}
      </div>
    </div>
  );
};
const styleSheet: { [key: string]: React.CSSProperties } = {
  lapped: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: 10,
  },
  inner: {
    margin: 10,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
  },
};
