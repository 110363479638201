import { useEffect, useState, useLayoutEffect } from "react";
import { MITAI_TEXTBUTTON } from "components/mitoyoAI-style";
import { ButtonList } from "components/ResultViewer/DataList/ButtonList";

export const ControlArea = (props) => {
  const { styles, AIList, frame, setFrame, addNewData, updateVideo } = props;
  const [previews, setPreviews] = useState([]);
  const [next, setNext] = useState([]);
  useEffect(() => {
    setPreviews(AIList.filter((d) => d.Frame < frame));
    setNext(AIList.filter((d) => d.Frame > frame));
  }, [AIList, frame]);

  return (
    <div style={styles.right4}>
      <div style={{ flex: 1 }} />
      <MITAI_TEXTBUTTON
        true={previews.length !== 0}
        onClick={() => {
          if (previews.length === 0) return;
          setFrame(previews[previews.length - 1].Frame);
          updateVideo(previews[previews.length - 1].Frame);
        }}
      >
        前の異常に移動
      </MITAI_TEXTBUTTON>
      <div style={{ flex: 1 }} />
      <AddDialogOverlay frame={frame} addNewData={addNewData} />
      <div style={{ flex: 1 }} />
      <MITAI_TEXTBUTTON
        true={next.length !== 0}
        onClick={() => {
          if (next.length === 0) return;
          setFrame(next[0].Frame);
          updateVideo(next[0].Frame);
        }}
      >
        次の異常に移動
      </MITAI_TEXTBUTTON>
      <div style={{ flex: 1 }} />
    </div>
  );
};

const AddDialogOverlay = (props) => {
  const { frame, addNewData } = props;
  const [isPopUp, setIsPopUp] = useState(false);
  useLayoutEffect(() => setIsPopUp(false), [frame]);
  const style = {
    override: {
      position: "absolute",
      height: "98vh",
      width: "98vw",
      display: isPopUp ? "block" : "none",
      left: 0,
      top: 0,
    },
    relative: { position: "relative" },
  };

  return (
    <>
      <div style={style.override} onClick={() => setIsPopUp(false)} />
      <div style={style.relative}>
        <ButtonList
          listType={"New"}
          check={-1}
          selecting={isPopUp}
          onClick={(id) => addNewData(id)}
          style={{ bottom: 180, right: -40, top: "unset", width: 210 }}
        />

        <MITAI_TEXTBUTTON
          true={frame !== -1}
          onClick={() => frame !== -1 && setIsPopUp(true)}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            <div>手動で状態を登録</div>
            <Triangle />
          </span>
        </MITAI_TEXTBUTTON>
      </div>
    </>
  );
};

const Triangle = () => (
  <div
    style={{
      width: 10,
      height: 10,
      borderTop: "5px solid transparent",
      borderBottom: "5px solid white",
      borderRight: "5px solid transparent",
      borderLeft: "5px solid transparent",
      marginBottom: 5,
      marginLeft: 5,
    }}
  />
);
