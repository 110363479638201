import {
  MITAI_TABBUTTON,
  MITAI_TABBUTTONType,
} from "components/mitoyoAI-style";
import { FC } from "react";

type ListType = "AI" | "User" | "Final" | "NonReport" | "Parts" | "NotIssue";
type Props = {
  styles: React.CSSProperties;
  listType: ListType;
  setListType: React.Dispatch<React.SetStateAction<ListType>>;
};
export const DataListTab: FC<Props> = ({ styles, listType, setListType }) => {
  const tabData: (lt: ListType, title: string) => MITAI_TABBUTTONType = (
    lt,
    title
  ) => ({
    Bcolor: "#A9D18E",
    current: listType === lt,
    onClick: () => setListType(lt),
    position: "Top",
    children: title,
  });

  return (
    <div style={styles}>
      <MITAI_TABBUTTON {...tabData("AI", "AIスコア")} />
      <MITAI_TABBUTTON {...tabData("Parts", "付属部品")} />
      <MITAI_TABBUTTON {...tabData("User", "手動登録")} />
      <MITAI_TABBUTTON {...tabData("NonReport", "報告しない")} />
      <MITAI_TABBUTTON {...tabData("NotIssue", "異常ではない")} />
      <MITAI_TABBUTTON {...tabData("Final", "出力予定")} />
    </div>
  );
};
