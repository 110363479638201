import React, { useEffect, useState, FC } from "react";
import { Amplify, I18n } from "aws-amplify";
import { Font } from "@react-pdf/renderer";
import { Translations } from "@aws-amplify/ui-components";

import { Menu } from "components/Menu";
import { WelcomePage } from "page/WelcomePage";
import { ResultsViewer } from "page/ResultsViewer";

import awsExports from "./aws-exports";
import IPA from "fonts/ipaexg.ttf";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Header } from "components/Header/Header";
import { AuthComponents } from "components/AuthComponents";

Amplify.configure(awsExports);

I18n.putVocabulariesForLanguage("ja", {
  [Translations.CREATE_ACCOUNT_TEXT]: "アカウントの作成",
  [Translations.EMAIL_LABEL]: "メールアドレス *",
  [Translations.EMAIL_PLACEHOLDER]: " ",
  [Translations.FORGOT_PASSWORD_TEXT]: "パスワードをお忘れの場合",
  [Translations.PASSWORD_LABEL]: "パスワード *",
  [Translations.PASSWORD_PLACEHOLDER]: "パスワードを入力してください。",
  [Translations.RESET_PASSWORD_TEXT]: "パスワードを変更する",
  [Translations.SIGN_IN_ACTION]: "ログイン",
  [Translations.SIGN_IN_HEADER_TEXT]: "ログイン",
  [Translations.USERNAME_LABEL]: "ユーザーID",
  [Translations.USERNAME_PLACEHOLDER]: "ユーザーIDを入力してください。",
  [Translations.RESET_YOUR_PASSWORD]: "パスワードをリセット",
  [Translations.BACK_TO_SIGN_IN]: "戻る",
  [Translations.SEND_CODE]: "コードを送信",
  [Translations.NO_ACCOUNT_TEXT]: "アカウントがありませんか？",
});

export default function App() {
  useEffect(() => Font.register({ family: "Koruri", src: IPA }), []);

  return (
    <Authenticator.Provider>
      <Apps />
    </Authenticator.Provider>
  );
}

const Apps: FC = () => {
  const [isTopMenu, setIsTopMenu] = useState<boolean>(true);
  const { authStatus } = useAuthenticator((context) => [context.user]);

  return (
    <div className="App" style={styleSheet.App}>
      <Header isTopMenu={isTopMenu} setTopPage={() => setIsTopMenu(true)} />
      {authStatus === "authenticated" ? (
        <div style={styleSheet.Normal}>
          {isTopMenu ? (
            <WelcomePage nextPage={() => setIsTopMenu(false)} />
          ) : (
            <ResultsViewer />
          )}
        </div>
      ) : (
        <div style={styleSheet.Login}>
          <AuthComponents />
        </div>
      )}
    </div>
  );
};

const styleSheet: { [key: string]: React.CSSProperties } = {
  App: {
    display: "flex",
    height: "100vh",
    width: "100%",
    flexDirection: "column",
    overflow: "hidden",
    cursor: "default",
  },
  Normal: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    height: "100%",
  },
  Login: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
};
