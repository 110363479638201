export const filterCheckStatus = (d, index, borderData, loadData, listType) => {
  //データを↓の情報でフィルタリングする
  //topはスコアがボーダーラインを越えており、スコアが前後の値より高い(山である)場合にtrueを返す
  switch (listType) {
    case "AI": //スコアがボーダーラインを越えており、スコアが前後の値より高い(山である)場合に報告状態は関係せず出力
      if (d.Score > borderData) {
        if (index > 0) {
          if (
            d.Score > loadData[index - 1].Score &&
            d.Score > loadData[index + 1].Score
          ) {
            return true;
          }
        }
      }
      return false;
    case "Parts": //付属部品として登録したデータ
      return d.Check === 400 || d.Check === 401;
    case "User": //人間が追加したデータ
      return (
        d.Check === 101 || d.Check === 201 || d.Check === 301 || d.Check === 401
      );
    case "NonReport": //人間が報告しないとしたデータ
      return d.Check === 2 || d.Check === 200 || d.Check === 201;
    case "NotIssue": //AIの誤検知としたデータ
      return d.Check === 3 || d.Check === 300 || d.Check === 301;
    case "Final": //人間が追加したデータと、AIで人間がヨシした最終出力となるデータ
      return d.Check === 1 || d.Check === 100 || d.Check === 101;
    default:
  }
};
