export function csvText_to_json(data) {
  const splitEachLine = data.split("\n");
  let topLine = undefined;
  let returnData = [];
  splitEachLine.forEach((element, index) => {
    const splitCommaEachLine = element.split(",");
    switch (index) {
      case 0:
        topLine = splitCommaEachLine;
        break;
      default:
        const makeObject = {};
        for (let d = 0; d < splitCommaEachLine.length; d++) {
          makeObject[topLine[d]] =
            parseFloat(splitCommaEachLine[d]) || splitCommaEachLine[d];
        }
        if (isNaN(makeObject.Frame)) break;
        returnData.push(makeObject);
        break;
    }
  });
  return returnData;
}
