import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { FC } from "react";

type Props = {
  setUserMenu: (userMenu: boolean) => void;
};
export const HeaderUserMenu: FC<Props> = (props) => {
  const { setUserMenu } = props;
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  console.log(user);
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
      onClick={() => setUserMenu(false)}
    >
      <div
        style={{
          position: "absolute",
          right: 0,
          top: 50,
          width: 200,
          height: 140,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          borderRadius: 10,
          paddingBottom: 10,
          boxShadow: "0px 0px 11px #afabab",
        }}
      >
        <div style={{ flex: 1, paddingTop: "auto", paddingBottom: "auto" }}>
          <p style={{ fontSize: 20 }}>
            {user.attributes?.preferred_username || user.attributes?.email}様
          </p>
        </div>
        <div
          style={{
            flex: 1,
            marginLeft: 10,
            marginRight: 10,
            backgroundColor: "#A9D18E",
            color: "white",
            fontWeight: "bold",
          }}
          onClick={signOut}
        >
          <p>ログアウト</p>
          {/* <AmplifySignOut
            buttonText="ログアウト"
            style={{ backgroundColor: "white" }}
          /> */}
        </div>
      </div>
    </div>
  );
};
