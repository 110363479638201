import { CSSProperties, FC } from "react";

export const MITAI_BORDERLINE: FC = () => {
  return (
    <div style={{ width: "100%", height: 1, backgroundColor: "#A9D18E" }} />
  );
};

export const MITAI_TOPTEXT: FC<{ children: string }> = (props) => {
  return (
    <div style={{ fontSize: 30, color: "darkgray", userSelect: "none" }}>
      {props.children}
    </div>
  );
};
export const MITAI_SUBTITLETEXT: FC<{ children: string }> = (props) => {
  return (
    <div style={{ fontSize: 20, color: "darkgray", userSelect: "none" }}>
      {props.children}
    </div>
  );
};

type MITAI_TEXTBUTTONType = {
  true?: boolean;
  false?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
  children: string | JSX.Element;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};
export const MITAI_TEXTBUTTON: FC<MITAI_TEXTBUTTONType> = (props) => {
  return (
    <div
      {...props}
      style={{
        backgroundColor: props.true
          ? "#559C27"
          : props.false
          ? "#A9D18E55"
          : "#A9D18E",
        color: "white",
        padding: 3,
        margin: 2,
        fontSize: 18,
        cursor: "pointer",
        userSelect: "none",
      }}
    >
      {props.children}
    </div>
  );
};

export type MITAI_TABBUTTONType = {
  current: boolean;
  Bcolor: string;
  position: "Top" | "Bottom";
  onClick?: () => void;
  children: string | JSX.Element;
  style?: React.CSSProperties;
};
export const MITAI_TABBUTTON: FC<MITAI_TABBUTTONType> = (props) => {
  const position = () => {
    let commonData: {
      fontSize: number;
      paddingLeft: number;
      paddingRight: number;
      marginBottom?: number;
      marginTop?: number;
    }[] = [
      {
        fontSize: 18,
        paddingLeft: 8,
        paddingRight: 8,
      },
      {
        fontSize: 16,
        paddingLeft: 5,
        paddingRight: 5,
      },
    ];
    switch (props.position) {
      case "Bottom":
        commonData[1]["marginBottom"] = 5;
        return commonData;
      default:
        commonData[1]["marginTop"] = 5;
        return commonData;
    }
  };
  const commonParam = {
    display: "flex",
    flexDirection: "column",
    color: "white",
    cursor: "pointer",
    userSelect: "none",
    marginLeft: 5,
    marginRight: 5,
  };
  const param = props.current
    ? {
        backgroundColor: props.Bcolor,
        ...position()[0],
        ...commonParam,
      }
    : {
        backgroundColor: props.Bcolor + "88",
        ...position()[1],
        ...commonParam,
      };
  return (
    <div
      style={{ ...(param as CSSProperties), ...props.style }}
      onClick={props.onClick}
    >
      <div style={{ flex: 1 }} />
      {props.children}
      <div style={{ flex: 1 }} />
    </div>
  );
};
