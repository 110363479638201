import { memo, useEffect, useLayoutEffect, useRef, useState } from "react";
import { MITAI_BORDERLINE } from "components/mitoyoAI-style";
import { csvText_to_json } from "util/csvText_to_json";
import RightWindow from "components/RightWindow";
import 鉄塔 from "assets/tower.jpg";
import OutputPDF, { OutputAllPDF } from "./outputPDF";
import { Storage } from "@aws-amplify/storage";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Brush,
} from "recharts";
import { jsonToCsvText } from "util/json_to_csvText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-input-slider";
import { BreadData } from "components/ResultViewer/BreadData";
import { PDFTabMenu } from "components/ResultViewer/PDFTabMenu";
import { ControlArea } from "components/ResultViewer/ControlArea";
import { DataList } from "components/ResultViewer/DataList/DataList";
import { getWindowSize } from "util/getWindowSize";

import { filterCheckStatus } from "util/resultcsv_control/filterCheckStatus";

export default function ResultVideoDisplay(props) {
  const { props: pp, navigation } = props;
  const { dataset, bread, dataList } = pp;
  const [AIList, setAIList] = useState([]);
  /**
   *
   *
   * props:{
   *  props: pp
   *  navigation: ページ推移スクリプト
   * }
   *
   * pp:{
   *  bread:足跡
   *  dataList:
   *  dataset:
   * }
   *
   * bread:[
   *  {
   *    dataset:
   *    page:"SelectData2"
   *   }
   *  {
   *    dataset:
   *    page:"resultVideo"
   *   }
   * ]
   *
   *
   * dataset:{
   *  checkDate: 日付
   *  dataCount: データ数量
   *  dataDir: aws内部ディレクトリ
   *  etc: メモ
   *  lineData:線種情報
   *  lineName: 路線名
   *  status: status
   * }
   *
   *
   *
   *
   *
   */
  //ref設定
  const useVideo = useRef(); //ビデオ制御用
  const [videoStatus, setVideoStatus] = useState(false);
  //state設定
  const [loadData, setLoadData] = useState(undefined); //読み込まれたCSVが変化したjsonを保存
  const [originalLoadData, setOriginalLoadData] = useState(undefined);
  const [isEdited, setIsEdited] = useState(false);

  const [frame, setFrame] = useState(-1); //現在開いているフレーム数を指定
  const [rightMenu, toggleRightMenu] = useState(false); // 右のメニューが展開されているかどうか
  const [PDFView, setPDFView] = useState(false); //PDF表示
  const [windowSize, setWindowSize] = useState(getWindowSize()); //ウインドウサイズ取得
  const [borderData, setBorderData] = useState(
    "theshold" in dataset ? parseFloat(dataset.theshold) : 0.07
  );
  const [originalBorderData, setOriginalBorderData] = useState(
    "theshold" in dataset ? parseFloat(dataset.theshold) : 0.07
  );

  //S3のデータベース取得
  useLayoutEffect(() => {
    Storage.get(
      `${bread[0].dataset.dataDir}/${dataset.dataDir}/inspection_data.csv`,
      {
        level: "private",
        contentType: "text/plain",
        download: true,
        cacheControl: "no-store",
      }
    )
      .then((res) => res.Body.text())
      .then((text) => {
        let textData = text.replace("\r", "").split("\n");
        //テキストデータに新型項目を追記する
        if (
          textData[0] ==
          ",Frame,Score,Abnormal,Check,GPS (Lat.) [deg],GPS (Long.) [deg]"
        ) {
          text = textData
            .map((d, index) =>
              index === 0 ? d + ",Etc,Description" : d + ",,"
            )
            .join("\n");
        }
        const data = csvText_to_json(text);
        setLoadData(data);
        setOriginalLoadData(data);
      });
  }, []);

  useEffect(() => {
    const returnData = loadData
      ? loadData.filter((d, index) =>
          filterCheckStatus(d, index, borderData, loadData, "AI")
        )
      : [];
    setAIList(returnData);
  }, [borderData, loadData]);

  useEffect(() => {
    switch (true) {
      case JSON.stringify(originalLoadData) !== JSON.stringify(loadData):
      case borderData !== originalBorderData:
        setIsEdited(true);
        break;
      default:
        setIsEdited(false);
    }
  }, [loadData, originalLoadData, borderData, originalBorderData]);

  //ウインドウサイズ取得
  useEffect(() => {
    window.addEventListener("resize", () => setWindowSize(getWindowSize()));
    setWindowSize(getWindowSize());
  }, []);

  const [videoWidth, setVideoWidth] = useState(400);
  const saveStatus = (update) => {
    const data = jsonToCsvText(update);
    Storage.put(
      `${bread[0].dataset.dataDir}/${dataset.dataDir}/inspection_data.csv`,
      data,
      {
        level: "private",
        contentType: "text/plain",
      }
    ).catch(() => {});
    let updateData = dataList;
    const pos = updateData.findIndex(
      (value) => value.dataDir === dataset.dataDir
    );
    updateData[pos]["theshold"] = borderData;
    Storage.put(
      `${bread[0].dataset.dataDir}/dataList.json`,
      JSON.stringify(updateData),
      {
        level: "private",
        contentType: "text/plain",
      }
    );
    setOriginalLoadData(update);
    setOriginalBorderData(borderData);
  };
  return (
    <div style={styles.main1}>
      {/* パンくずリストの横棒 */}
      <div style={styles.main2}>
        <div style={styles.main3}>
          <BreadData navigation={navigation} pp={pp} />
          {isEdited && (
            <div
              style={{ display: "flex", flexDirection: "row", marginLeft: 10 }}
              onClick={() => saveStatus(loadData)}
            >
              <FontAwesomeIcon
                icon={faSave}
                size="lg"
                style={{
                  height: "1.25em",
                  cursor: "pointer",
                }}
                color={"#A9D18E"}
              />
              <p
                style={{
                  fontSize: "1.25em",
                  color: "darkgray",
                  marginTop: 0,
                  marginBottom: 0,
                  cursor: "pointer",
                  fontStyle: "italic",
                }}
              >
                {"保存されていません"}
              </p>
            </div>
          )}

          <div style={{ flex: 1 }} />
          <PDFTabMenu PDFView={PDFView} setPDFView={setPDFView} frame={frame} />
        </div>
      </div>
      {/* 本体 */}
      <div style={styles.main3}>
        {/* 左パネル */}
        <DataList
          borderData={borderData}
          frame={frame}
          setFrame={setFrame}
          setPDFView={setPDFView}
          setVideoStatus={setVideoStatus}
          loadData={loadData}
          setLoadData={setLoadData}
          useVideo={useVideo}
          openRightMenu={() => {
            if (frame == -1) return null;
            var ret = ("000000" + frame).slice(-6);
            Storage.get(
              `${bread[0].dataset.dataDir}/${dataset.dataDir}/all/${ret}.png`,
              {
                level: "private",
                download: true,
              }
            ).then((d) =>
              toggleRightMenu([
                <FrameDeteilView
                  bread={bread}
                  frame={frame}
                  image={d.Body}
                  loadData={loadData}
                  styles={styles}
                />,
                "詳細情報",
                1000,
              ])
            );
          }}
        />
        {/* 右側 */}
        {PDFView ? (
          <div style={styles.right1}>
            {PDFView === "single" ? (
              /* PDF一枚 */
              <OutputPDF //{ frame, props, csvData }
                frame={frame}
                csvData={loadData}
                {...props}
              />
            ) : (
              /* PDF全部 */
              <OutputAllPDF csvData={loadData} {...props} />
            )}
          </div>
        ) : (
          /* 一般チェック */
          <div style={styles.right2}>
            <VideoPlayer
              useVideo={useVideo}
              setFrame={setFrame}
              setVideoWidth={setVideoWidth}
              windowSize={windowSize}
              bread={bread}
              dataset={dataset}
              videoStatus={videoStatus}
              setVideoStatus={setVideoStatus}
            />

            <LineChartBox
              useVideo={useVideo}
              frame={frame}
              setFrame={setFrame}
              borderData={borderData}
              setBorderData={setBorderData}
              videoWidth={videoWidth}
              windowSize={windowSize}
              loadData={loadData}
              videoStatus={videoStatus}
              setVideoStatus={setVideoStatus}
              AIList={AIList}
            />

            <ControlArea
              styles={styles}
              AIList={AIList}
              frame={frame}
              setFrame={setFrame}
              addNewData={(number) => {
                if (frame === -1) return;
                // eslint-disable-next-line no-restricted-globals
                if (!confirm("異常報告を追加しますか？")) return;
                const data = JSON.parse(JSON.stringify(loadData));
                data[frame].Check = number;
                setVideoStatus(true);
                setLoadData(data);
                setPDFView(false);
              }}
              updateVideo={(data) => {
                setVideoStatus(true);
                useVideo.current &&
                  useVideo.current.paused &&
                  (useVideo.current.currentTime = data / 30);
              }}
            />
          </div>
        )}
      </div>

      {rightMenu && (
        <RightWindow
          closeButton={() => toggleRightMenu(false)}
          width={rightMenu[2]}
          title={rightMenu[1]}
        >
          {rightMenu[0]}
        </RightWindow>
      )}
    </div>
  );
}

const VideoPlayer = (props) => {
  const {
    useVideo,
    setFrame,
    setVideoWidth,
    windowSize,
    bread,
    dataset,
    videoStatus,
    setVideoStatus,
  } = props;

  const [url, setVideoData] = useState(undefined); //ビデオのアドレス取得用
  useEffect(() => {
    if (!useVideo.current) return;
    useVideo.current.playbackRate = 0.25;
  }, [url]);
  //動画ファイルインポート
  useEffect(() => {
    Storage.get(`${bread[0].dataset.dataDir}/${dataset.dataDir}/marge.mp4`, {
      level: "private",
      download: false,
    }).then(setVideoData);
  }, []);
  return (
    <video
      src={url}
      id="mov"
      onTimeUpdate={(r) => {
        videoStatus || setFrame(parseInt(r.target.currentTime * 30));
        setVideoStatus(false);
      }}
      onLoadedDataCapture={(data) =>
        data.target.clientWidth && setVideoWidth(data.target.clientWidth)
      }
      ref={useVideo}
      controls
      style={{
        height: windowSize ? windowSize.height / 2 - 40 : "30vh",
        width: "auto",
      }}
    />
  );
};

const LineChartBox = (props) => {
  const {
    borderData,
    setBorderData,
    videoWidth,
    windowSize,
    loadData,
    setFrame,
    useVideo,
    frame,
    videoStatus,
    setVideoStatus,
    AIList,
  } = props;
  const [modeChange, setModeChange] = useState(false);

  const [areaData, setAreaData] = useState([0, 200]);

  const CartesianGridRef = useRef();
  const [AIFrameList, setAIFrameList] = useState([]);
  useEffect(() => {
    setAIFrameList(AIList.map((d) => d.Frame));
  }, [AIList]);
  useEffect(() => {
    if (100 < frame && frame + 100 < loadData.length) {
      setAreaData([frame - 100, frame + 100]);
    } else if (frame < 100) {
      setAreaData([0, 200]);
    } else if (frame + 100 > loadData.length) {
      setAreaData([loadData.length - 100, loadData.length]);
    }
  }, [frame]);
  const [isHoverNumberArea, setIsHoverNumberArea] = useState(false);
  const lineStyleRefs = {
    flexDirection: "column",
    display: "flex",
    height: CartesianGridRef.current
      ? CartesianGridRef.current.props.height
      : 10,
    zIndex: 1,
  };
  const borderSettingDivStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    userSelect: "none",
  };
  return (
    <div style={{ flex: 1, flexDirection: "row", display: "flex" }}>
      <div
        style={{
          ...lineStyleRefs,
          alignContent: "center",
          border: "solid 1px",
          borderRadius: 5,
          textAlign: "center",
          textAlignVertical: "center",
          margin: 5,
        }}
      >
        <div
          style={{ ...borderSettingDivStyle, borderBottom: "solid 1px" }}
          onClick={() => {
            switch (true) {
              case borderData * 100 + 1 > 60:
                setBorderData(0.6);
                return;
              case borderData * 100 + 1 < 0:
                setBorderData(0);
                return;
              default:
                setBorderData(Math.round((borderData * 100 + 1) * 100) / 10000);
                return;
            }
          }}
        >
          <div style={{ flex: 1 }} />
          <div style={{ fontSize: 40 }}>+</div>
          <div style={{ flex: 1 }} />
        </div>
        <div
          style={borderSettingDivStyle}
          onMouseEnter={() => setIsHoverNumberArea(true)}
          onMouseLeave={() => setIsHoverNumberArea(false)}
        >
          <div style={{ flex: 1 }} />

          <div
            style={{
              display: isHoverNumberArea ? "none" : "unset",
              width: 50,
              flex: 1,
            }}
          >
            {(borderData * 100) / 100}
          </div>
          <input
            type="number"
            max={0.6}
            min={0}
            step={0.01}
            value={borderData}
            onChange={(event) => {
              switch (true) {
                case event.currentTarget.value > 0.6:
                  setBorderData(0.6);
                  return;
                case event.currentTarget.value < 0:
                  setBorderData(0);
                  return;
                default:
                  setBorderData(event.currentTarget.value);
                  return;
              }
            }}
            style={{
              width: 50,
              flex: 1,
              display: isHoverNumberArea ? "unset" : "none",
            }}
          />
          <div style={{ flex: 1 }} />
        </div>
        <div
          style={{ ...borderSettingDivStyle, borderTop: "solid 1px" }}
          onClick={() => {
            switch (true) {
              case borderData * 100 - 1 > 60:
                setBorderData(0.6);
                return;
              case borderData * 100 - 1 <= 0:
                setBorderData(0);
                return;
              default:
                setBorderData(Math.round((borderData * 100 - 1) * 100) / 10000);
                return;
            }
          }}
        >
          <div style={{ flex: 1 }} />
          <div style={{ fontSize: 40 }}>-</div>
          <div style={{ flex: 1 }} />
        </div>
      </div>
      <div style={{ ...lineStyleRefs, paddingTop: 5, paddingLeft: 10 }}>
        <Slider
          axis="y"
          y={borderData * 100}
          ystep={0.01 * 100}
          ymax={0.6 * 100}
          yreverse
          onChange={({ y }) => setBorderData(y / 100)}
        />
      </div>

      <LineChart
        style={{ marginLeft: -8, marginBottom: modeChange ? 0 : 30 }}
        width={videoWidth - 60}
        height={
          modeChange
            ? windowSize
              ? (windowSize.height * 10) / 48 + 30
              : 210
            : windowSize
            ? (windowSize.height * 10) / 48
            : 210
        }
        data={loadData}
        onClick={(d) => {
          setVideoStatus(true);
          d && setFrame(d.activeLabel);
          d &&
            useVideo.current.paused &&
            (useVideo.current.currentTime = d.activeLabel / 30);
        }}
      >
        <Line
          type="monotone"
          isAnimationActive={false}
          dataKey="Score"
          stroke="#8884d8"
          dot={<Dot AIFrameList={AIFrameList} />}
        />
        <CartesianGrid stroke="#ccc" ref={CartesianGridRef} />
        {modeChange ? (
          <XAxis dataKey="Frame" />
        ) : (
          <XAxis
            allowDataOverflow
            dataKey="Frame"
            domain={areaData}
            type={"number"}
          />
        )}
        <YAxis />
        <ReferenceLine x={frame} stroke="red" />
        <ReferenceLine
          y={0.5}
          stroke="red"
          ifOverflow="extendDomain"
          strokeWidth={0}
        />
        <ReferenceLine y={borderData} stroke="red" />
        <Tooltip />
        {modeChange && (
          <Brush
            height={30}
            travellerWidth={0}
            gap={50}
            onChange={(d) => setModeChange([d.startIndex, d.endIndex])}
            endIndex={loadData && loadData.length > 200 && 200}
          />
        )}
      </LineChart>
      <div style={{ width: 0, height: 0 }}>
        <div style={styles.right3}>
          <input
            type={"checkbox"}
            defaultChecked={!modeChange}
            onClick={() => setModeChange(!modeChange)}
          />
          <p style={{ margin: 0, fontSize: "0.8rem" }}>自動追尾</p>
        </div>
      </div>
    </div>
  );
};

const DotSVG = memo(({ cx, cy }) => {
  return (
    <svg
      width={10}
      height={10}
      x={cx - 5}
      y={cy - 5}
      viewbox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <circle fill="#929ee2" cx="5" cy="5" r="5"></circle>
    </svg>
  );
});
const Dot = (props) => {
  const { cx, cy, stroke, payload, value, index, AIFrameList } = props;
  if (!AIFrameList.includes(payload.Frame)) return null;
  return <DotSVG cx={cx} cy={cy} key={payload.Frame} />;
};

const FrameDeteilView = ({ bread, frame, image, loadData, styles }) => {
  const pp = Math.round((frame / loadData.length) * 1000) / 10;
  const leftRound = Math.round(260 * (frame / loadData.length));
  return (
    <div style={styles.left8}>
      <div style={styles.left9}>異常箇所</div>
      <MITAI_BORDERLINE />
      <div style={styles.left10}>
        <div style={styles.left11}>
          <p>線路名 {bread[0].dataset.lineName}</p>
          <p>区間 {bread[1].dataset.positionName}</p>
          <p>線種 {bread[1].dataset.lineData}</p>
          <p>径間長 {bread[1].dataset.lineWidth}m</p>
        </div>
        <div style={styles.left12}>
          <img src={鉄塔} width={300} style={styles.left13} alt="" />
          <div style={{ ...styles.left14, left: 25 + leftRound }}>
            <p>{pp}%</p>
            <p>{Math.round(bread[1].dataset.lineWidth * (pp / 100))}m</p>
          </div>
          <p style={{ position: "absolute", left: 20, top: 170 }}>No.2</p>
          <p style={{ position: "absolute", left: 280, top: 170 }}>No.1</p>

          {/* leftの値は15-275px */}
          <div style={styles.left15} />
          <div style={{ ...styles.left16, left: 20 + leftRound }} />
          <div style={{ ...styles.left17, width: leftRound }} />

          <div style={{ ...styles.left18, left: 15 + leftRound }} />
        </div>
      </div>
      <div style={styles.left19}>AI解析データ</div>
      <MITAI_BORDERLINE />
      <img src={URL.createObjectURL(image)} width={800} alt="" />
    </div>
  );
};

const styles = {
  main1: { display: "flex", flexDirection: "column", flex: 1 },
  main2: { display: "flex", flexDirection: "row" },
  main3: { flex: 1, display: "flex", flexDirection: "row" },
  main5: {
    flex: 1,
    borderStyle: "solid",
    borderColor: "#A9D18E",
    alignItems: "baseline",
    fontSize: 15,
  },
  main6: {
    display: "flex",
    flexDirection: "row",
    borderWidth: 2,
    borderTopWidth: 0,
    padding: 5,
    borderStyle: "solid",
    borderColor: "#A9D18E",
  },
  left1: {
    display: "flex",
    flexDirection: "column",
    borderWidth: 2,
    borderRightWidth: 0,
    borderColor: "#A9D18E",
    borderStyle: "solid",
    height: "calc(100vh - 180px)",
    flex: 6,
  },
  left2: { display: "flex", flexDirection: "row", height: 30, marginTop: 5 },
  left3: {
    display: "flex",
    flex: 3,
    flexDirection: "column",
    overflow: "auto",
    borderWidth: 2,
    borderBottomWidth: 0,
    borderStyle: "solid",
    borderColor: "#A9D18E",
  },
  left4: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "unset",
    cursor: "default",
  },
  left5: { display: "flex", flexDirection: "row", padding: 10, flex: 1 },
  left6: { display: "flex", flexDirection: "row", cursor: "default" },
  left7: {
    display: "flex",
    flexDirection: "row",
    padding: 2,
    alignContent: "center",
    alignItems: "center",
  },
  left8: {
    display: "flex",
    flex: 1,
    width: "100%",
    height: "100%",
    flexDirection: "column",
  },
  left9: { fontSize: 20, color: "darkgray", marginTop: 8 },
  left10: { display: "flex", flexDirection: "row", padding: 10, flex: 1 },
  left11: { flex: 1, display: "block" },
  left12: { flex: 1, height: 200, position: "relative" },
  left13: { position: "absolute", left: 0, top: 90 },
  left14: { position: "absolute", top: 0 },
  left15: {
    width: 1,
    height: 100,
    backgroundColor: "blue",
    borderRadius: 10,
    position: "absolute",
    left: 20,
    top: 35,
  },
  left16: {
    width: 1,
    height: 100,
    backgroundColor: "blue",
    borderRadius: 10,
    position: "absolute",
    top: 35,
  },
  left17: {
    height: 1,
    backgroundColor: "blue",
    borderRadius: 10,
    position: "absolute",
    left: 20,
    top: 50,
  },
  left18: {
    width: 10,
    height: 10,
    backgroundColor: "blue",
    borderRadius: 10,
    position: "absolute",
    top: 130,
  },
  left19: { fontSize: 20, color: "darkgray", marginTop: 8 },
  right1: {
    flex: 5,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "#EF2929",
    paddingRight: 4,
    paddingBottom: 4,
    height: "calc(100vh - 180px)",
  },
  right2: {
    flex: 5,
    alignContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    borderWidth: 2,
    borderColor: "#A9D18E",
    borderLeftWidth: 0,
    borderStyle: "solid",
    height: "calc(100vh - 180px)",
  },
  right3: {
    position: "relative",
    right: "5rem",
    top: 0,
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    width: "5rem",
    marginTop: 10,
  },
  right4: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    paddingLeft: 70,
  },
};
