import { useState } from "react";
import { DataListHeader } from "components/ResultViewer/DataList/DataListHeader";
import { EachLine } from "components/ResultViewer/DataList/EachLine";
import { HeadDataLine } from "components/ResultViewer/DataList/HeadLine";
export const EachDataListBox = (props) => {
  const {
    loadData,
    frame,
    setLoadData,
    setVideoStatus,
    setPDFView,
    setFrame,
    openRightMenu,
    filteredData,
    listType,
    useVideo,
    borderData,
  } = props;

  const [multiSelect, setMultiSelect] = useState([]);

  return (
    <>
      <div
        style={{
          ...styles,
          flex: "unset",
          borderBottom: 0,
          overflow: "visible",
        }}
      >
        <DataListHeader />
        <HeadDataLine
          setFrame={setFrame}
          loadData={loadData}
          setLoadData={setLoadData}
          useMultiSelect={{ multiSelect, setMultiSelect }}
          filteredData={filteredData}
          borderData={borderData}
          listType={listType}
          setPDFView={setPDFView}
          setVideoStatus={setVideoStatus}
          frame={frame}
        />
      </div>
      <div style={{ ...styles, borderTop: 0 }}>
        {filteredData.map((fd) => (
          <EachLine
            fd={fd}
            frame={frame}
            setFrame={setFrame}
            loadData={loadData}
            setLoadData={setLoadData}
            listType={listType}
            setPDFView={setPDFView}
            setVideoStatus={setVideoStatus}
            useVideo={useVideo}
            openRightMenu={openRightMenu}
            useMultiSelect={{ multiSelect, setMultiSelect }}
            key={fd.Frame}
          />
        ))}
      </div>
    </>
  );
};
const styles = {
  display: "flex",
  flex: 3,
  flexDirection: "column",
  overflow: "auto",
  borderWidth: 2,
  borderBottomWidth: 0,
  borderStyle: "solid",
  borderColor: "#A9D18E",
};
