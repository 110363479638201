import { FC, useEffect, useState } from "react";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { faCloud } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Storage } from "@aws-amplify/storage";
import { MITAI_TEXTBUTTON, MITAI_TABBUTTON } from "components/mitoyoAI-style";
import { Document, Page, Text } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
type Props = {
  nextPage: () => void;
};
export const WelcomePage: FC<Props> = (props) => {
  //S3のデータベース取得
  const [uploadPath, setUploadPath] = useState("");
  useEffect(() => {
    Storage.get(`path.txt`, {
      level: "private",
      contentType: "text/plain",
      download: true,
      cacheControl: "no-store",
    })
      .then((res) => {
        if (!res.Body)
          throw new Error(
            "ファイルアップロード先アドレスが提示されていません。"
          );
        return (res.Body as Blob).text();
      })
      .then(setUploadPath)
      .catch((exception) => {
        alert("ファイルアップロード先アドレスが提示されていません。");
        setUploadPath("");
      });
  }, []);
  const updatePath = (string: string) => {
    Storage.put(`path.txt`, string, {
      level: "private",
      contentType: "text/plain",
    }).catch(() => {});
    setUploadPath(string);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        flex: 1,
        padding: 10,
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <h1>AI送電線点検結果表示システム</h1>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            width: 400,
            padding: 20,
            margin: 20,
            boxShadow: "0px 0px 11px #afabab",
          }}
          onClick={props.nextPage}
        >
          <FontAwesomeIcon
            icon={faPlayCircle}
            style={{ width: 180, height: 180, color: "#A9D18E", margin: 10 }}
          />
          <p style={{ fontSize: 30 }}>ビューワーへ移動</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            width: 400,
            padding: 20,
            margin: 20,
            boxShadow: "0px 0px 11px #afabab",
          }}
          onClick={() => {
            uploadPath
              ? window.open(uploadPath)
              : alert(
                  "アップロード先が設定されていません。管理者に確認をとってください。"
                );
          }}
        >
          <FontAwesomeIcon
            icon={faCloud}
            style={{ width: 180, height: 180, color: "#A9D18E", margin: 10 }}
          />
          <p style={{ fontSize: 30 }}>ファイルのアップロード</p>
        </div>
      </div>
      <PDFViewer
        style={{ flex: 3, width: "100%", height: "100%", display: "none" }}
      >
        <Document>
          <Page size="A4" orientation="landscape">
            <Text
              style={{
                fontFamily: "Koruri",
              }}
            >
              株式会社 三豊AI開発
            </Text>
          </Page>
        </Document>
      </PDFViewer>
      {uploadPath == "" && <InputPanel updatePath={updatePath} />}
    </div>
  );
};
type InputPanelProps = {
  updatePath: (string: string) => void;
};
const InputPanel: FC<InputPanelProps> = ({ updatePath }) => {
  const [path, setPath] = useState("");
  return (
    <div style={{ width: 700, padding: 20, margin: 20 }}>
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <MITAI_TABBUTTON
            Bcolor={"#A9D18E"}
            current={true}
            position={"Bottom"}
          >
            アップロード先アドレス
          </MITAI_TABBUTTON>
          <div style={{ flex: 1 }} />
          <MITAI_TEXTBUTTON onClick={() => updatePath(path)}>
            保存
          </MITAI_TEXTBUTTON>
        </div>
        <textarea
          style={{
            flex: 1,
            borderStyle: "solid",
            borderColor: "#A9D18E",
            alignItems: "baseline",
            fontSize: 15,
          }}
          value={path}
          onChange={(d) => setPath(d.target.value)}
        />
      </div>
    </div>
  );
};
